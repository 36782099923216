
<template>
  <div class="approve-container" v-if="hasBookingPermission">
    <!--
    <el-tabs v-model="activeName" >
        <el-tab-pane label="待审批" name="waiting">
            <BookingList
            ref="bookingList0"
            :type="0"
            @submitCheck="handleSubmitCheck"></BookingList>
        </el-tab-pane>
        <el-tab-pane label="审批通过" name="passed">
          <BookingList
          ref="bookingList1"
          :type="1"
          @submitCheck="handleSubmitCheck"></BookingList>
        </el-tab-pane>
        <el-tab-pane label="驳回" name="refused">
          <BookingList
          ref="bookingList2"
          :type="2"
          @submitCheck="handleSubmitCheck"></BookingList>
        </el-tab-pane>
        <el-tab-pane label="全部" name="all">
          <BookingList
          ref="bookingList"
          :type="-1"
          @submitCheck="handleSubmitCheck"></BookingList>
        </el-tab-pane>
    </el-tabs>
    -->
    <el-tabs v-model="activeName" @tab-click="handleMainTabClick">
      <el-tab-pane label="待审批" name="waiting"></el-tab-pane>
      <el-tab-pane label="已通过" name="passed"></el-tab-pane>
      <el-tab-pane label="已驳回" name="refused"></el-tab-pane>
      <el-tab-pane label="全部" name="all"></el-tab-pane>
    </el-tabs>
    <el-row class="filter-pane" type="flex" justify="start">
      <el-col :span="6">
        <el-select
          v-model="currRoomId"
          clearable
          placeholder="全部会议室"
          @change="handleRoomChange">
          <el-option
            v-for="room in roomList"
            :key="room.id"
            :label="room.name"
            :value="room.id">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="16">
        <span>会议时间：</span>
        <el-radio-group v-model="monthType" @change="handleMonthTypeChange">
          <el-radio-button label="0">{{prevMonthText}}</el-radio-button>
          <el-radio-button label="1">{{currMonthText}}</el-radio-button>
          <el-radio-button label="2">{{nextMonthText}}</el-radio-button>
        </el-radio-group>
        <el-date-picker
          v-model="currMonthRange"
          type="monthrange"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="handleDateRangeChange"
          >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="15" type="flex" justify="start">
      <el-col :span="2">
          <el-popconfirm            
            title="确定审批通过所选中的会议预定记录？"
            @confirm="handleBatchPass"
          >
            <el-button type="primary" plain
            :disabled="batchPassBtnDisabled"
            slot="reference">批量通过</el-button>
          </el-popconfirm>
      </el-col>
      <el-col :span="2">
        <el-popconfirm            
            title="确定驳回所选择的会议预定记录？"
            @confirm="handleBatchRefused"
          >
          <el-button
            type="primary" plain
            :disabled="batchRefuseBtnDisabled"
            slot="reference">批量驳回</el-button>
        </el-popconfirm>

      </el-col>
      <el-col :span="2">
        <el-button
          type="primary" plain 
          @click="handleClearFiltersClick">重置过滤器</el-button>
      </el-col>
      <el-col :span="17" style="text-align:right;">
        <el-popconfirm
            title="确定审批通过所有待审批的会议预定记录？"
            @confirm="handlePassAll"
          >
          <el-button v-if="activeName == 'waiting'"
            class="pass-all-btn"
            type="primary" title="通过所有待审批预定记录"
            slot="reference">一键全部通过</el-button>
        </el-popconfirm>
      </el-col>
    </el-row>
    <el-table v-loading="loading"
      ref="orderListTable"
      border
      :data="orderList"
      stripe
      style="width: 100%;"
      :default-sort = "{prop : 'meetingDate', order: 'descending'}"
      highlight-current-row
      @selection-change="handleSelectionChange"
      @filter-change = "handleTableFilterChange">
      <el-table-column type="selection" width="55" align="center"
        
      />
      <el-table-column label="ID" width="55" prop="id"/>
      <el-table-column label="会议室" prop="meetingRoom.name" 
        sortable
        :filters="roomNameFilters"
        :filter-method="handleTableFilter">
          <template slot-scope="scope">
              <div>{{scope.row.meetingRoom.name}}</div>
              <div class="col-subtitle el-icon-location-outline">{{scope.row.meetingRoom.building}}</div>
          </template>
      </el-table-column>
      <el-table-column label="会议时间" prop="meetingTime" width="200"
        sortable
        :filters="meetingDateFilters"
        :filter-method="handleTableFilter"
        >
          <template slot-scope="scope">
            <div style="font-weight:bold;">{{ parseTime(scope.row.meetingTime, '{y}-{m}-{d}') }}&nbsp;{{scope.row.timeRange}}</div>
          </template>
      </el-table-column>
      <el-table-column label="预约人" prop="user.userName" width="100"
        sortable
        :filters="bookUserFilters"
        :filter-method="handleTableFilter"
        >
        <template slot-scope="scope">
            <div>{{scope.row.user.userName}}</div>            
        </template>
      </el-table-column>
      <el-table-column label="预约时间" prop="submitTime" width="160"
        sortable
        :filters="bookTimeFilters"
        :filter-method="handleTableFilter"
        >
        <template slot-scope="scope">
            <div>{{ parseTime(scope.row.submitTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</div>            
          </template>
      </el-table-column>
      <el-table-column label="主题" prop="title">
          <template slot-scope="scope">
              <div>{{scope.row.title}}</div>
              <div class="col-subtitle el-icon-chat-dot-round">{{getMeetingTypeName(scope.row.meetingType)}}</div>
            </template>
      </el-table-column>
      <el-table-column label="人数" prop="totals" width="60" align="center"/>
      <el-table-column label="布置及会务需求" prop="layoutDemands">
          <template slot-scope="scope">
              <div>{{scope.row.layoutDemands}}</div>
              <div>{{scope.row.meetingDemands}}</div>
            </template>
      </el-table-column>
      
      <el-table-column label="审批人" prop="checkUser.userName" width="160">
          <template slot-scope="scope">
              <div>{{scope.row.checkUser.userName}}</div>
              <div v-show="scope.row.checkTime" class="col-subtitle el-icon-time">{{ parseTime(scope.row.checkTime, '{y}-{m}-{d} {h}:{i}:{s}') }}</div>
            </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="warning" size="small" effect="dark">待审批</el-tag>
          <el-tag v-if="scope.row.status == 1" type="success" size="small" effect="dark">已通过</el-tag>
          <el-tag v-if="scope.row.status == 2" type="danger" size="small" effect="dark">已驳回</el-tag>
          <el-tag v-if="scope.row.status == 3" type="info" size="small" effect="dark">已取消</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
            <el-popconfirm
              :title="getConfirmTitle(1, scope.row.status)"
              :key="'ok'+scope.row.id"
              @confirm="handleCheckOne(scope.row, 1)"
            >
              <el-button
                v-if="scope.row.status == 0 || scope.row.status == 2"
                size="mini"
                type="text"
                icon="el-icon-circle-check"                
                slot="reference"
              >通过</el-button>
            </el-popconfirm>          

            <el-popconfirm
              :title="getConfirmTitle(2, scope.row.status)"
              :key="'cancel'+scope.row.id"
              @confirm="handleCheckOne(scope.row, 2)"
            >
              <el-button
                v-if="scope.row.status == 0 || scope.row.status ==  1"
                style="color: #f56c6c;"
                size="mini"
                type="text"
                icon="el-icon-video-pause"                
                slot="reference"
              >驳回</el-button>
            </el-popconfirm>          
        </template>
      </el-table-column>

    </el-table>
    <div style="text-align:right;margin:10px 0px;">
      <el-pagination
        v-show="totals > 0"
        background
        layout="total, sizes, prev, pager, next"
        :total="totals"
        :page-sizes="[10, 20, 50, 100, 150, 200]"        
        :current-page.sync="queryParams.page"
        :page-size.sync="queryParams.size"
        @current-change="getOrderList"
        @size-change="handlePageSizeChange"
        >
      </el-pagination>
    </div>


  </div>
  <div v-else style="margin-top:50px;">
    <p>您没有权限访问。</p>
  </div>
</template>

<script>
//import BookingList from './bookinglist';
import { getMeetingTypeName, parseTime } from "@/utils/wmeeting";
import * as consts from "@/utils/consts";
import { getMeetingRooms, searchOrderList, queryMeetingRoomOrderList, submitCheckInfo, submitBatchCheckInfo, batchCheckAllOrders,} from "@/api/meetingRoomApi";
import moment from 'moment';
import userUtil from "@/utils/userUtil";


export default {
  components: {},
  props: {},
  data() {
    return {
      activeName: 'waiting',
      refreshList: false,
      // 筛选条件
      currRoomId: null,

      batchPassBtnDisabled: true,
      batchRefuseBtnDisabled: true,

      monthType: -1, // 0-上个月 1 -当月 2-下个月 3-自定义 -1 表示不选中

      prevMonthText: "上个月",
      currMonthText: "本月",
      currMonth: null,
      nextMonthText: "下个月",

      currMonthRange: null,

      roomList: [],

      loading: false,
      sortOrders:['descending', 'ascending', null],
      orderList: [],
      totals: 0,
      queryParams:{
        page: 1,
        size: 20,
        startDate: null,
        endDate: null,
        roomIds: '',
        status: 0,
        userId: null,
        sortFields: [
          {
            "prop":"meetingRoom.name",
            "name": "roomname",
            "sort": 0,
          },
          {
            "prop":"meetingDate",
            "name": "meetingdate",
            "sort": 2,
          },
          {
            "prop":"user.userName",
            "name": "username",
            "sort": 0,
          },
        ]
      },
      sortInited: true,
      multiSelection: [],
      // 筛选
      bookUserFilters: [],
      meetingDateFilters: [],
      roomNameFilters: [],
      bookTimeFilters: [],

      checkDialogVisible: false,
      approveOrder: null,
      userInfo: null,
      hasBookingPermission: false,
    };
  },
  methods: {
    getMeetingTypeName, parseTime,
    handleSubmitCheck(orderId, status){
        this.$refs.bookingList.refresh();
        this.$refs.bookingList0.refresh();
        this.$refs.bookingList1.refresh();
        this.$refs.bookingList2.refresh();
    },

    // 获得会议室列表
    getMeetingRoomList(){
        this.loading = true;
        getMeetingRooms().then(response => {
            this.roomList = response.data;
            this.loading = false;
        });
    },

    resetTables(){
      this.multiSelection = [];
      this.bookUserFilters = [];
      this.meetingDateFilters = [];
      this.bookUserFilters = [];
      this.bookTimeFilters = [];
      if(this.$refs.orderListTable != null){
        this.$refs.orderListTable.sort('meetingDate', 'descending');
      }
      this.handleClearFiltersClick();
    },

    getOrderList(){
      this.loading = true;      
      this.approveOrder = null;    
      this.resetTables();  

      queryMeetingRoomOrderList(this.queryParams).then(response => {
      if(response.data != null && response.data.list != null){
              this.orderList = response.data.list;
              this.totals = response.data.totals;
              this.buildFilters();
          }else{
              this.orderList = [];
              this.totals = 0;
          }
          this.loading = false;
      }).catch((err)=>{
          this.orderList = [];
          this.totals = 0;
          this.loading = false;
      });
    },

    buildFilters(){
      if(this.orderList != null){
         this.orderList.forEach(item =>{
            let index = this.findFilterData(item.meetingRoom.name, this.roomNameFilters);
            if(index == -1){
               this.roomNameFilters.push({
                  text: item.meetingRoom.name,
                  value: item.meetingRoom.name
               });
            }

            let date1 = parseTime(item.meetingTime, "{y}-{m}-{d}");
            index = this.findFilterData(date1, this.meetingDateFilters);
            if(index == -1){
               this.meetingDateFilters.push({
                  text: date1,
                  value: date1
               });
            }

            index = this.findFilterData(item.user.userName, this.bookUserFilters);
            if(index == -1){
               this.bookUserFilters.push({
                  text: item.user.userName,
                  value: item.user.userName
               });
            }
            
            let date2 = parseTime(item.submitTime, "{y}-{m}-{d}");
            index = this.findFilterData(date2, this.bookTimeFilters);
            if(index == -1){
               this.bookTimeFilters.push({
                  text: date2,
                  value: date2
               });
            }
         });
      }
    },    

    findFilterData(val, filters){
      let index = -1;

      filters.forEach((item, i)=>{
          if(val == item.value){
            index = i;
            return;
          }
      });
      return index;
    },

    rowSelectable(rowItem, index){
      if(rowItem.status == 0){
        return true;
      }
      return false;
    },

    handleMainTabClick(tab, event){
      /*
      if(tab.name == "waiting" || tab.name == "all"){
        this.batchPassBtnDisabled = false;
        this.batchRefuseBtnDisabled = false;
      }else if(tab.name == "passed"){
        this.batchPassBtnDisabled = true;
        this.batchRefuseBtnDisabled = false;
      }else if(tab.name == "refused"){
        this.batchPassBtnDisabled = false;
        this.batchRefuseBtnDisabled = true;
      }
      */
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.multiSelection = selection;
      if(this.multiSelection == null || this.multiSelection.length == 0){
        this.batchPassBtnDisabled = true;
        this.batchRefuseBtnDisabled = true;
      }else{
        if(this.activeName == "waiting" || this.activeName == "all"){
          this.batchPassBtnDisabled = false;
          this.batchRefuseBtnDisabled = false;
        }else if(this.activeName == "passed"){
          this.batchPassBtnDisabled = true;
          this.batchRefuseBtnDisabled = false;
        }else if(this.activeName == "refused"){
          this.batchPassBtnDisabled = false;
          this.batchRefuseBtnDisabled = true;
        }
      }
    },

    handleTableFilterChange(filters){
      //console.log(filters);
    },

    handlePageSizeChange(val){
        this.queryParams.size = val;
        if(this.queryParams.page * val > this.totals){
          this.queryParams.page = 1;
        }
        
        this.getOrderList();
    },

    // 处理表头
    handleTableHeaderClass({column}){      
      if(column.sortable == "custom"){
        //console.log("handleTableHeaderClass:", column.label, column.multiOrder);
        if(column.multiOrder){
          column.order = column.multiOrder;
        }else{
          // 处理默认排序
          if(column.property == "meetingDate" && this.sortInited == true){
            column.order = column.sortOrders[0];
          }else{
            column.order = null;
          }
        }
      }
    },

    // 处理点击表头排序的三角图形事件
    handleTableSortChange({column}){
      //console.log(column, column.property, column.label, column.order);
      if(column.sortable !== "custom"){
        return;
      }
      this.sortInited = false;
      if(column.order == null){
        column.multiOrder = null;
      }else if(column.order == "descending"){
        column.multiOrder = "descending";
      }else if(column.order == "ascending"){
        column.multiOrder = "ascending";
      }

      // 设定排序字段
      this.queryParams.sortFields.forEach(item=>{
          if(item.prop == column.property){
            if(column.order == null){
              item.sort = 0;
            }else if(column.order == "descending"){
              item.sort = 2;
            }else if(column.order == "ascending"){
              item.sort = 1;
            }
            return;
          }
      });    
      
      // 从服务端排序 --- 刷新当前列表
      //this.getOrderList();
      // 在前端页面排序
      this.sortColumnData(column);
    },

    // 实现当页排序
    sortColumnData(column){
      if(column.multiOrder == null){
        return;
      }
      let tableData = this.orderList;

      if(column.order == "descending"){
          tableData = tableData.sort((itemA, itemB) =>{
            let valA = this.getRowItemValue(itemA, column.property);
            let valB = this.getRowItemValue(itemB, column.property);
            if(!isNaN(valA)){
              return valB - valA;
            }else{              
              let flag = valA > valB;
              return flag ? -1 : 1;
            }
          });
      }else if(column.order == "ascending"){
        tableData = tableData.sort((itemA, itemB) =>{
            let valA = this.getRowItemValue(itemA, column.property);
            let valB = this.getRowItemValue(itemB, column.property);
            if(!isNaN(valA)){
              return valA - valB;
            }else{              
              let flag = valA < valB;
              return flag ? -1 : 1;
            }
          });
      }
      this.orderList = tableData;
    },

    getRowItemValue(item, prop){      
      if(prop == "meetingRoom.name"){
        return item.meetingRoom.name;
      }else if(prop == "meetingDate"){
        return item.meetingDate;
      }else if(prop == "user.userName"){
        return item.user.userName;
      }
      return null;
    },

    handleTableFilter(value, row, column){
        //console.log(value, row, column);
        let prop = column.property;
        if(prop == "meetingRoom.name"){
          return row.meetingRoom.name === value;
        }else if(prop == "meetingTime"){
          let time1 = parseTime(value, "{y}-{m}-{d}");
          let time2 = parseTime(row.meetingTime, "{y}-{m}-{d}");
          return time1 === time2;
        }else if(prop == "user.userName"){
          return row.user.userName === value;
        }else if(prop == "submitTime"){
          let time1 = parseTime(value, "{y}-{m}-{d}");
          let time2 = parseTime(row.submitTime, "{y}-{m}-{d}");
          return time1 === time2;
        }
    },

    getConfirmTitle(action, status){
      let title = "";

      // 通过动作
      if(action == 1){
        title = "确定通过该预定记录？";
        if(status == consts.WMEET_CHECK_STATUS_REFUSED){
          title = "该记录已审批驳回，是否重新通过？";
        }      
      }else if(action == 2){
        title = "确定驳回该预定记录？";
        if(status == consts.WMEET_CHECK_STATUS_DONE){
          title = "该记录已审批通过，是否重新驳回？";
        }    
      }
      return title;
    },

    // 批量审核通过
    handleBatchPass(){
      if(this.multiSelection == null || this.multiSelection.length == 0){
        return;
      }
      this.doSubmitBatchCheck(consts.WMEET_CHECK_STATUS_DONE);
    },

    // 批量拒绝
    handleBatchRefused(){
      if(this.multiSelection == null || this.multiSelection.length == 0){
        return;
      }
      this.doSubmitBatchCheck(consts.WMEET_CHECK_STATUS_REFUSED);
    },

    handleClearFiltersClick(){
      if(this.$refs.orderListTable != null){
        this.$refs.orderListTable.clearFilter();
      }
    },

    // 审批通过所有待审核记录
    handlePassAll(){
      batchCheckAllOrders(
        consts.WMEET_CHECK_STATUS_DONE,
        ''
      ).then((result) => {
        if(result.code != 200){
          this.$message.error(result.msg);
          return;
        }
        // 刷新当前列表
        this.getOrderList();
      });
    },

    // 提交批量审核信息
    doSubmitBatchCheck(status){
      let ids = [];

      this.multiSelection.map(item =>{
          ids.push(item.id);
      });

      submitBatchCheckInfo(
        ids,
        status,
        ''
      ).then((result) => {
        if(result.code != 200){
          this.$message.error(result.msg);
          return;
        }
        // 刷新当前列表
        this.getOrderList();
      });
    },

    handleCheckOne(rowItem, status){
      submitCheckInfo(
        rowItem.id,
        status,
        null
      ).then((result) => {
        if(result.code != 200){
          this.$message.error(result.msg);
          return;
        }
        // 刷新当前列表
        this.getOrderList();
      });
    },

    // 初始化月份选择器
    setMonthTabs(){
      this.currMonth = moment();

      let curr = moment().month()+1;
      let prev = moment(this.currMonth).add(-1, "M").month()+1;
      let next = moment(this.currMonth).add(1, "M").month()+1;

      this.currMonthText = "本月("+ curr +"月)";
      this.prevMonthText = "上月("+ prev +"月)";
      this.nextMonthText = "下月("+ next +"月)";
    },

    initDateRange(){
      this.setMonthTabs();
      this.setDateRange();
    },

    // 设置时间范围
    setDateRange(){
      let date = null;
      if(this.monthType == 0){
        date = moment().add(-1, "M").startOf('months').format('YYYY-MM-DD');
        this.queryParams.startDate = this.getTimestamp(date);

        date = moment().add(-1, "M").endOf('months').format('YYYY-MM-DD');
        this.queryParams.endDate = this.getTimestamp(date);

      }else if(this.monthType == 1){
        date = moment().startOf('months').format('YYYY-MM-DD');
        this.queryParams.startDate = this.getTimestamp(date);

        date = moment().endOf('months').format('YYYY-MM-DD');
        this.queryParams.endDate = this.getTimestamp(date);

      }else if(this.monthType == 2){
        date = moment().add(1, "M").startOf('months').format('YYYY-MM-DD');
        this.queryParams.startDate = this.getTimestamp(date);

        date = moment().add(1, "M").endOf('months').format('YYYY-MM-DD');
        this.queryParams.endDate = this.getTimestamp(date);
      }else if(this.monthType == 3){
        date = moment(this.currMonthRange[0]).startOf('months').format('YYYY-MM-DD');
        this.queryParams.startDate = this.getTimestamp(date);

        date = moment(this.currMonthRange[1]).endOf('months').format('YYYY-MM-DD');
        this.queryParams.endDate = this.getTimestamp(date);
      }

    },

    getTimestamp(dateString){
      return new Date(dateString + " 00:00:00").getTime()/1000;
    },

    initData(){
      if(this.hasBookingPermission){
        //this.monthType = 1;
        this.initDateRange();
        this.getMeetingRoomList();
        this.getOrderList();
      }      
    },

    handleRoomChange(val){
      this.queryParams.roomIds = val;
      this.getOrderList();
    },

    handleMonthTypeChange(val){
      this.monthType = val;
      this.currMonthRange = null;
      this.setDateRange();
      this.getOrderList();
    },

    handleDateRangeChange(val){
      this.currMonthRange = val;
      this.monthType = 3;
      this.setDateRange();
      this.getOrderList();
    },
    // 获取用户审核信息
    getUserInfo(){
      setTimeout(() => {
        this.userInfo = this.$store.getters['account/user'];
        if (userUtil.isRole(this.userInfo, "checkuser")) {
          this.hasBookingPermission = true;
          this.initData();
        }
      }, 200);
    },
  },
  created() {
    this.getUserInfo();

  },
  mounted() {},
  watch:{
    'activeName': function(val){
        if(val == 'waiting'){
          this.queryParams.status = 0;
        }else if(val == 'refused'){
          this.queryParams.status = 2;
        }else if(val == 'passed'){
          this.queryParams.status = 1;
        }else{
          this.queryParams.status = null;
        }
        
        this.getOrderList();
    }
  },
};
</script>

<style scoped>


.approve-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 20px 20px 20px;
}

.filter-pane{
  background-color: #F5F7FA;
  margin-top: 10px;
  box-sizing: border-box;  
}
.col-subtitle{
    font-size: 12px;
    color: #909399;
}
.el-row{
  padding: 10px;
}

.pass-all-btn, .pass-all-btn:focus, .pass-all-btn:hover{
    background-color: rgba(148, 8, 11, 1);
    border-color: rgba(148, 8, 11, 1);
    color: #fff;
}
.pass-all-btn:hover{
    opacity: 0.8;
}

/deep/ .el-table{
  color: #000;
}
/deep/ .el-tabs__item{
  font-size: 16px;
}
/deep/.approve-container .el-tabs__item.is-active, /deep/.approve-container .el-tabs__item:hover{
  background: none;
  color: rgba(148, 8, 11, 1);
}
/deep/.approve-container .el-tabs__active-bar{
  background-color: rgba(148, 8, 11, 1);
}
/deep/.approve-container .el-tabs__header{
  margin: 0px;
}
</style>
